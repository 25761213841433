import { Box, Grid, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Crumbs, DataRow, DateDisplay, MonoText, Title, TraxxButton } from 'app/components';
import { API } from 'app/services';
import { snakeToTitle, useAsyncTask, useRouter } from 'app/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as BrowserLink } from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  table: {
    marginTop: 14,
    borderRadius: 5,
  },
  title: { marginTop: 15, textDecoration: 'underline' },
  head: {
    fontWeight: 'bold',
    maxWidth: 200,
  },
  subtitle: {
    opacity: '70%',
  },
  warningTable: { marginBottom: 25, border: true, 'border-bottom-style': 'dotted' },
  tag: {
    margin: '2px',
  }
}));

let mounted = false;
function MatchDetail({ routeType = 'account' }) {
  const classes = useStyles();
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const screening_id = params.screening_id;
  const match_id = params.match_id;
  const { enqueueSnackbar } = useSnackbar();
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [errorCatcher] = useAsyncTask('getScreening');
  const [runUnresolve] = useAsyncTask('unresolve');
  const [matching, setMatching] = useState(null);
  const [matchRawResult, setMatchRawResult] = useState(null);
  const [printPreview, setPrintPreview] = useState(false);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getMatchDetail();
    // eslint-disable-next-line
  }, [access]);

  const getMatchDetail = () => {
    if (!screening_id || !match_id) return enqueueSnackbar('invalid id', { variant: 'error' });
    errorCatcher(async () => {
      const loader = routeType === 'account' ? API.Screening.get_match_detail : API.Admin.get_match;
      const { model } = await loader(screening_id, match_id);
      if (mounted) {
        setMatching(model);
        const { content, aml_types, lists, media, peps, sanctions } = model.screen_result_raw;
        setMatchRawResult({
          ...JSON.parse(content),
          aml_types: JSON.parse(aml_types),
          lists: JSON.parse(lists),
          media: JSON.parse(media),
          peps: JSON.parse(peps),
          sanctions: JSON.parse(sanctions)
        });
      }
    });
  };

  const getScreenDetailPath = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/screenings/${id}/detail`;
      default:
        return `/screenings/${id}/detail`;
    }
  };

  const getCaseDetailPath = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/screenings/cases/${id}/detail`;
      default:
        return `/screenings/cases/${id}/detail`;
    }
  };

  const unResolve = () => {
    runUnresolve(async () => {
      await API.Users.unresolve_matches(screening_id, {
        resolve_ids: [match_id],
      });
      enqueueSnackbar('Match unresolved', { variant: 'success' });
      getMatchDetail();
    });
  };

  return (
    <Box className={classes.root}>
      {!printPreview && (
        <>
          <Crumbs
            current="Detail"
            key="crumbs"
            links={[
              {
                text: 'Screening',
                href: routeType === 'admin' ? '/admin/screenings' : '/screenings',
                key: 'screening',
              },
              {
                text: params.screening_id,
                href: routeType === 'admin' ? `/admin/screenings/${params.screening_id}/detail` : `/screenings/${params.screening_id}/detail`,
                kegy: 'screeningId',
              },
            ]}
          />
          <Title
            prefix={'Match detail'}
            floatButton={
              matching?.profile_retrieved_at && {
                onClick: () => {
                  setPrintPreview(true);
                },
                text: `Preview print document`,
              }
            }>
            {matching && 'Powered by ComplyAdvantage'}
          </Title>
          <Grid spacing={2} container>
            <Grid item md={6} xs={12}>
              <TableContainer component={Paper} className={classes.table}>
                {loading.getScreening && <LinearProgress size={30} />}
                {!loading.getScreening && matching && (
                  <Table>
                    <TableBody>
                      <DataRow name="Name" value={matching.name} />
                      <DataRow name="Match rating" value={snakeToTitle(matching.match_rating)} />
                      <DataRow name="Match type" value={matching.match_type} />
                      {(matching.screen.type === 'simple' || matching.screen.type === 'passport') && <DataRow name="Gender" value={snakeToTitle(matching.gender || '')} />}
                      <DataRow name="Created date" value={<DateDisplay date={matching.created_at} />} />
                      <DataRow name="Match ID" value={<MonoText>{matching.reference}</MonoText>} />
                      <DataRow
                        name="Screening ID"
                        value={
                          <Link underline="always" component={BrowserLink} to={getScreenDetailPath(matching.screen?.id)}>
                            <MonoText>{matching.screen?.reference}</MonoText>
                          </Link>
                        }
                      />
                      {matching.screen && matching.screen.case && (
                        <DataRow
                          name="Case ID"
                          value={
                            <Link underline="always" component={BrowserLink} to={getCaseDetailPath(matching.screen?.case?.id)}>
                              <MonoText>{matching.screen?.case?.reference}</MonoText>
                            </Link>
                          }
                        />
                      )}
                      <DataRow name="Resolved" value={<DateDisplay date={matching.resolved_at} />} />
                      {matching.resolved_reason && matching.resolved_at && <DataRow name="Resolved reason" value={<DateDisplay date={matching.resolved_reason} />} />}
                      {matching.resolved_at && routeType !== 'admin' && (
                        <DataRow
                          name=""
                          value={
                            <TraxxButton loading={loading.unresolve} onSubmit={() => unResolve()}>
                              <Typography>Unresolve</Typography>
                            </TraxxButton>
                          }
                        />
                      )}
                      {matching.profile_retrieved_at && <DataRow name="Profile retrieved date" value={<DateDisplay date={matching.profile_retrieved_at} />} />}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Grid>
            <Grid item md={6} xs={12}>
              <TableContainer component={Paper} className={classes.table}>
                <Table>
                  <TableBody>
                    <Title prefix={'Key Information'} className={classes.title} />
                    <TableRow>
                      <TableCell>
                        <Typography className={classes.head} variant="body1">
                          Name
                        </Typography>
                        <Typography className={classes.subtitle} color="secondary" variant="body2">
                          {matchRawResult?.detail?.profile?.matching_name || '-'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {matching?.screen.type === 'organisation' ? (
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.head} variant="body1">
                            Company Names
                          </Typography>
                          {matchRawResult?.detail?.profile?.company?.names.map((alias, index) => (
                            <Typography className={classes.subtitle} color="secondary" variant="body2" key={`alias` + { index }}>
                              {alias.name}
                            </Typography>
                          )) || '-'}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.head} variant="body1">
                            Aliases:
                          </Typography>
                          {matchRawResult?.detail?.profile?.person?.names.map((alias, index) => (
                            <Typography className={classes.subtitle} color="secondary" variant="body2" key={`alias` + { index }}>
                              {alias.name}
                            </Typography>
                          )) || '-'}
                        </TableCell>
                      </TableRow>
                    )}
                    {matching?.screen.type !== 'organisation' && (
                      <>
                        <TableRow>
                          <TableCell>
                            <Typography className={classes.head} variant="body1">
                              Associates
                            </Typography>
                            {matchRawResult?.detail?.profile?.person?.associates.map((person) => (
                              <Typography className={classes.subtitle} color="secondary" variant="body2">
                                [{person.type}] {person.name}
                              </Typography>
                            )) || '-'}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography className={classes.head} variant="body1">
                              Images
                            </Typography>
                            {matchRawResult?.detail?.profile?.person?.images.map((image) => (
                              <>
                                <Link href={image.url}>{image.url}</Link>
                                <Typography className={classes.subtitle} color="secondary" variant="body2">
                                  Source: {image.source}
                                </Typography>
                              </>
                            )) || '-'}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography className={classes.head} variant="body1">
                              Related Countries
                            </Typography>
                            {matchRawResult?.detail?.profile?.person?.places_of_birth.map((country) => (
                              <Typography className={classes.subtitle} color="secondary" variant="body2">
                                {country}
                              </Typography>
                            )) || '-'}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {matching?.screen.type !== 'organisation' && (
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.head} variant="body1">
                            Date of Birth
                          </Typography>
                          {matchRawResult?.detail?.profile?.person?.dates_of_birth.map((dob, index) => (
                            <Typography className={classes.subtitle} color="secondary" variant="body2" key={`dob_` + index}>
                              {dob.value} from '{dob.source}'
                            </Typography>
                          )) || '-'}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>
                        <Typography className={classes.head} variant="body1">
                          Risk Type:
                        </Typography>
                        {matchRawResult?.aml_types?.map((aml, index) => (
                          <Typography className={classes.subtitle} color="secondary" variant="body2" key={'aml_' + index}>
                            {aml}
                          </Typography>
                        ))}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Table 1 - Lists */}
            {matchRawResult?.lists.length !== 0 && (
              <Grid item md={6} xs={12}>
                <TableContainer component={Paper} className={classes.table}>
                  <Table>
                    <Title prefix={"Lists"} className={classes.title} />
                    {matchRawResult?.lists.map((warningList, index) => (
                      <TableBody className={classes.warningTable} key={'listTable_' + index}>
                        <TableRow>
                          <TableCell className={classes.title}>Mention {index + 1}</TableCell>
                          <TableCell>
                            {
                              warningList.aml_types.map((tag, index) => <Chip key={index} label={tag} size="small" variant="outlined" className={classes.tag} />)
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {matching?.screen.type === 'organisation' && 'Name: '}
                            {warningList.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Listed on: {warningList.listing_started_utc && moment(warningList.listing_started_utc).format("Do MMM YYYY")}</TableCell>
                        </TableRow>
                        {warningList.fields.map((fields, index) => (
                          <DataRow name={fields.name} key={'warningListField_' + index} value={fields.tag === 'related_url' || fields.name === 'Locationurl' || fields.tag === 'url' || fields.tag === 'picture_url' ? <Link href={fields.value}>{fields.value}</Link> : fields.value} />
                        ))}
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              </Grid>
            )}

            {/* Table 2 - Media */}
            {matchRawResult?.media.length !== 0 && (
              <Grid item md={6} xs={12}>
                <TableContainer component={Paper} className={classes.table}>
                  <Table>
                    <Title prefix="Media" className={classes.title} key={'MediaTitle'} />
                    {matchRawResult?.media.map((media, index) => (
                      <TableBody key={'MediaList_' + index}>
                        <TableRow key={'mediaName_' + index}>
                          <TableCell>{media.name}</TableCell>
                        </TableRow>
                        <TableRow key={'mediaInfo' + index}>
                          <TableCell colSpan={1} align="left">
                            Published {media?.publishing_date && moment(media.publishing_date).format("Do MMM YYYY")}
                          </TableCell>
                          <TableCell colSpan={2} align="left">
                            <Link underline="always" href={media.url}>
                              {media.title}
                            </Link>
                            <br />
                            {media.snippet}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              </Grid>
            )}

            {/* Table 3 - PEP */}
            {matchRawResult?.peps.length !== 0 && (
              <Grid item md={6} xs={12}>
                <TableContainer component={Paper} className={classes.table}>
                  <Table>
                    <Title prefix={"PEPs"} className={classes.title} />
                    {matchRawResult?.peps.map((warningList, index) => (
                      <TableBody className={classes.warningTable} key={'listTable_' + index}>
                        <TableRow>
                          <TableCell className={classes.title}>Mention {index + 1}</TableCell>
                          <TableCell>
                            {
                              warningList.aml_types.map((tag, index) => <Chip key={index} label={tag} size="small" variant="outlined" className={classes.tag} />)
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {matching?.screen.type === 'organisation' && 'Name: '}
                            {warningList.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Listed on: {warningList.listing_started_utc && moment(warningList.listing_started_utc).format("Do MMM YYYY")}</TableCell>
                        </TableRow>
                        {warningList.fields.map((fields, index) => (
                          <DataRow name={fields.name} key={'warningListField_' + index} value={fields.tag === 'related_url' || fields.name === 'Locationurl' || fields.tag === 'url' || fields.tag === 'picture_url' ? <Link href={fields.value}>{fields.value}</Link> : fields.value} />
                        ))}
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              </Grid>
            )}

            {/* Table 4 - Sanctions */}
            {matchRawResult?.sanctions.length !== 0 && (
              <Grid item md={6} xs={12}>
                <TableContainer component={Paper} className={classes.table}>
                  <Table>
                    <TableHead>
                      <Title prefix="Sanctions" className={classes.title} />
                      {matchRawResult?.sanctions.map((warningList) => (
                        <TableBody>
                          <TableRow>
                            <TableCell>{warningList.name}</TableCell>
                          </TableRow>
                          {
                            warningList.url && <TableRow>
                              <TableCell>URL</TableCell>
                              <TableCell><Link href={warningList.url}>{warningList.url}</Link></TableCell>
                            </TableRow>
                          }
                          <TableRow>
                            <TableCell align="left">Listed on: {warningList.listing_started_utc && moment(warningList.listing_started_utc).format("Do MMM YYYY")}</TableCell>
                          </TableRow>
                          {warningList.fields.map((fields) => (
                            <DataRow name={fields.name} value={fields.tag === 'related_url' || fields.name === 'Locationurl' || fields.tag === 'url' ? <Link href={fields.value}>{fields.value}</Link> : fields.value} />
                          ))}
                        </TableBody>
                      ))}
                    </TableHead>
                  </Table>
                </TableContainer>
              </Grid>
            )}

          </Grid>
        </>
      )}
    </Box>
  );
}

export default MatchDetail;
